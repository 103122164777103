import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Highlight from "~/components/highlight/highlight";
import Accordion from "~/components/accordion/accordion";
import OrderedList from "~/components/List/Ordered";
import UnorderedList from "~/components/List/Unordered";
import Button from "~/components/button";
import Icon from "~/content/Icon"

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 1"}
      color={"hs"}
      total_count={10}
      module_title="Separating the Good from the Bad"
      >
      <ContentWrapper>
        <H2 underline>Step 6</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>Work with your teammates to look at 3 different websites to see if they meet the criteria on the rubric. At this point, you do not need to read all the information on the websites. Instead, look for whether the features are present or not.</p>
            <p>Take these steps as you look at each website:</p>

            <OrderedList>
              <li>Locate 8 information icons <Icon /> next to website features.</li>
              <li>Click on the icon to read the information about the website feature.</li>
              <li>Decide how well the website handles each feature</li>

              <UnorderedList>
                <li>If the feature is present and done well, click the <i className="text-sm text-white fas fa-thumbs-up bg-lime-500 rounded-full px-4 py-2" />.</li>
                <li>If the feature is not present (or not done well), click the <i className="text-sm text-white fas fa-thumbs-down bg-red-500 rounded-full px-4 py-2" />.</li>
                <li>If you are not sure whether the website includes this feature—or you think this feature does not apply, click the <i className="text-sm text-white fas fa-thumbs-up bg-yellow-500 rounded-full px-2 py-4 -rotate-90 mx-2" />.</li>
              </UnorderedList>

              <li>Next, decide whether each icon relates to one of the criteria on your rubric. Use the "Website feature and quality" column on your rubric to keep track of how well the website meets each criterion. You can record your answers using symbols.</li>

              <div className="my-4 flex border-gray-900 border">
                <div className="flex-col">
                  <div className="font-bold border-gray-900 border-r border-b px-4 py-2">Rating of website feature</div>
                  <div className="border-r border-b border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-up bg-lime-500 rounded-full px-4 py-2" /></div>
                  <div className="border-b border-r border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-down bg-red-500 rounded-full px-4 py-2" /></div>
                  <div className="border-r border-gray-900 p-4 text-center"><i className="text-sm text-white fas fa-thumbs-up bg-yellow-500 rounded-full px-2 py-4 -rotate-90" /></div>
                </div>
                <div className="flex-col">
                  <div className="font-bold border-b border-gray-900 flex-col px-4 py-2">Rubric website feature and quality symbol</div>
                  <div className="border-b border-gray-900 p-4 text-center"><i className="fas fa-arrow-up text-xl" /></div>
                  <div className="border-b border-gray-900 p-4 text-center"><i className="fas fa-arrow-down text-xl" /></div>
                  <div className="p-4 text-center"><i className="fas fa-horizontal-rule text-xl" /></div>
                </div>
              </div>

              <li>If an icon does not match up with one of the criteria on the rubric, write a statement on the rubric that relates to the icon. Then rate that feature for the website you are looking at.</li>
              <li>In the last column, write a brief explanation for why you think the website does or does not meet the criteria. In other words, what are the strengths and weaknesses of each website for the listed criteria?</li>
            </OrderedList>

            <Highlight>Leave the Overall score column blank for now. You will assign a score later.</Highlight>

            <p>Click the buttons below to go to the different websites.</p>

            <div className="flex flex-col lg:flex-row">
              <Button
                action="secondary"
                className="my-2 lg:m-2"
                color="indigo"
                href="/article/safe-environment"
                icon="external"
                title="website 1 lesson 3"
                withIcon={true}
              >
                Website 1
              </Button>
              <Button
                action="secondary"
                className="my-2 lg:m-2"
                color="indigo"
                href="/article/active-life"
                icon="external"
                title="website 2 lesson 3"
                withIcon={true}
              >
                Website 2
              </Button>
              <Button
                action="secondary"
                className="my-2 lg:m-2"
                color="indigo"
                href="/article/lung-health"
                icon="external"
                title="website 3 lesson 3"
                withIcon={true}
              >
                Website 3
              </Button>
            </div>

            <p>When you are done evaluating your website, work with your teammates to compare the 3 sites. Decide what are the strongest and weakest parts of the different sites.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Have students work in teams of 3. (You may need one team of 2 or 4 depending on the number of students in your class.) If each student has a computer, each member of the team can choose 1 of the websites to examine. After each student finishes his or her website evaluation, the team can discuss and compare all three sites. If students are working in teams at one computer, they can go through all 3 of the websites together. In this case, each team member can take the lead on one copy of the rubric (for 1 site).</p>
            <p>This rubric includes 5 criteria that students will look for when they examine the websites. When students look at each website, they will see 8 icons <Icon /> at various places on the page. Students should click on each icon to learn more about what that website feature can tell them about the quality of the site. Students then use that information to make a judgment about that feature on that particular website. Students will color-code each feature and record information on their rubric.</p>
            <p>If helpful, you could project one of the website pages and go through the process of clicking each icon, reading the information, and making a judgment about the feature as a class before having teams continue on their own.</p>
            <p>Students should be able to match 5 of the icons to entries on the rubric. They should write statements on the rubric for the 3 icons representing criteria that are missing from the rubric. Encourage students to work together to come up with statements to add to the rubric.</p>

            <Accordion headerText="Note to teachers" className="my-4" open={true}>
              <p className="mt-0">During the field test, a few teachers indicated that their students had already covered this information in other classes. However, it appeared that students still did not fully understand why these website features are important to consider. If your students have had similar instruction before, encourage them to use this as a review. They could also move through this activity at a faster pace than if it was new material. Also, explain to them that they will use this information in a new way to develop their rubric.</p>
            </Accordion>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;
